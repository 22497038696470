define(["dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/5ad3f3c8377f2e400b3b7a604143d9710b5339db/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (query) {
  return {
    /**
     * Convert kebab-case input to camelCase
     *
     * @param {string} str input to convert
     *
     * @returns {string} - Input converted to camelCase
     */
    dashToCamel: function (str) {
      return str.replace(/(\-[a-z])/g, function (match) {
        return match.toUpperCase().replace("-", "");
      });
    },
    /**
     * Helper function to inline styles to the given dom
     *
     * @param {HTMLElement} rootDom - Element to style
     * @param {Object} styles - Element block styles
     *
     * @returns {HTMLElement} - Styled element
     */
    inline: function (rootDom, styles) {
      if (!styles) {
        return rootDom;
      }
      styles.forEach(function (style) {
        for (var styleProperty in style.props) {
          if (style.props.hasOwnProperty(styleProperty)) {
            var value = style.props[styleProperty].value;
            if (value && value !== "") {
              var selector = style.props[styleProperty].selector || style.selector;
              if (selector === "mcBlockContainer") {
                query(rootDom).style(this.dashToCamel(styleProperty), value);
              } else {
                query(selector, rootDom).style(this.dashToCamel(styleProperty), value);
              }
            }
          }
        }
      }.bind(this));
      return rootDom;
    },
    /**
     * Helper function to reset inline styles to the given dom
     *
     * @param {HTMLElement} rootDom - Element to style
     * @param {Object} styles - Element block styles
     *
     * @returns {HTMLElement} - Element with styles reset
     */
    reset: function (rootDom, styles) {
      if (!styles) {
        return rootDom;
      }
      styles.forEach(function (style) {
        for (var styleProperty in style.props) {
          if (style.props.hasOwnProperty(styleProperty)) {
            var selector = style.props[styleProperty].selector || style.selector;
            if (selector === "mcBlockContainer") {
              query(rootDom).style(this.dashToCamel(styleProperty), null);
            } else {
              query(selector, rootDom).style(this.dashToCamel(styleProperty), null);
            }
          }
        }
      }.bind(this));
      return rootDom;
    }
  };
});