define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/5ad3f3c8377f2e400b3b7a604143d9710b5339db/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare) {
  return declare([], {
    constructor: function (params) {
      this.inherited(arguments);
      var burger = document.querySelector(".burger-container");
      if (burger) {
        burger.addEventListener("click", function () {
          document.querySelector(".mc-nav-container").classList.toggle("menu-opened");
        });
      }
      document.addEventListener("click", function (event) {
        if (!event.target.matches(".menu-item-link")) {
          return;
        }
        document.querySelector(".mc-nav-container").classList.remove("menu-opened");
      }, false);
    }
  });
});